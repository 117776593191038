import React from 'react'
import  { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Login from '../Page/Login/Login'
import PageNotFound from '../Page/PageNotFound'
import Management from '../Page/Management/Management'
import UserOperation from '../Page/Management/com/UserOperation'
import ProjectOperation from '../Page/Management/com/ProjectOperation'


const Routing = () => {
    
  function ProtectedRoute(props) {
    if (localStorage.getItem('token') === null) {
      return <Navigate to='/login' />
    } else {
      return props.children
    }
  }
  return (
    <>
       <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Login />} />

      <Route path="*" element={<ProtectedRoute><PageNotFound /></ProtectedRoute>} />
 
      <Route path="/Management" element={<ProtectedRoute><Management /></ProtectedRoute>} />

      <Route path="/userOperation" element={<ProtectedRoute><UserOperation /></ProtectedRoute>} />
      <Route path="/ProjectOperation" element={<ProtectedRoute><ProjectOperation /></ProtectedRoute>} />
    </Routes>  
    </>
  )
}

export default Routing
