import React, { useEffect, useState } from 'react'

import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

import {  useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { loginSchema, userName, userPassword } from './../../validarionSchema/LoginSchema';
import { getInitialValues } from '../../extensions/FormikExtension'

import ApiCaller from '../../Api/ApiCaller'
import LoadingScreen from './../../Component/Navbar/LoadingScreen';
import { ToastTemplate, ToastType } from './../../notifications/ToastExecutor';

function Login() {
    const [showpassword, setShowPassword] = useState(false)
    const toggleShowPassword = () => setShowPassword(!showpassword)




    const navigate = useNavigate()
    


    const [loading, setLoading] = useState(false)




   async function login() {
        
        let body = {
            username: formik.getFieldMeta(userName.Name).value,
            password: formik.getFieldMeta(userPassword.Name).value
        }
        setLoading(true)
        let response = await new ApiCaller(`https://api.cie-contractors.org/user/login`).postData(body)
        if (response.Status) {
                     localStorage.setItem("userName",formik.getFieldMeta(userName.Name).value)
                     navigate('/Management',
                     {
                         state: formik.getFieldMeta(userName.Name).value
                        
                     })
                     localStorage.setItem("token", response.Result)
                   
    
                     setLoading(false)
    
                 } else {
                     new ToastTemplate(ToastType.Error, response.Message).execute()
                     setLoading(false)
                     localStorage.setItem("userName","")
                 }
        
       

    }   

    function rememberMe(){
        if(document.querySelector('.remember-me').checked){
            localStorage.setItem(
                "userData",
                JSON.stringify({
                    userName:formik.getFieldMeta(userName.Name).value,
                   
                }
                    
                )
            )
        }else{
            localStorage.setItem("userData","")
        }
    }

    function setUserData(){

            let data =localStorage.getItem('userData') !==""? JSON.parse(localStorage.getItem('userData')):""
            
      
    
            formik.setFieldValue(userName.Name,data.userName)
            
      
            
        
      

    }
    const formik = useFormik({
        initialValues: getInitialValues([userName, userPassword]),
        validationSchema: loginSchema,
        onSubmit: async () => {
            login()

        }
    })
    
    useEffect(()=>{
        
    


          if(localStorage.getItem("userData")=== null || localStorage.getItem("userData")=== ""){
            localStorage.setItem("userData","")
            formik.resetForm()
            document.querySelector('.remember-me').checked = false
          }else{

            setUserData()
            document.querySelector('.remember-me').checked = true
          }
    },[])

    return (
        <>
            {
                loading ? <LoadingScreen /> :
                    <div className='login d-flex align-items-center justify-content-center'>

                           
                            <div className='right-part col-lg-12 col-md-12'>

                                <div className='login-title'>
                                    <h1>CIE Contractors</h1>
                                    
                                </div>

                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                    }} className='login-form'>
                                    <div className='field-form-group'>
                                        <label htmlFor="" className='form-label' >User Name</label>
                                        <input type="text" className={
                                            formik.getFieldMeta(userName.Name).touched
                                             && formik.getFieldMeta(userName.Name).error != null ?
                                             "form-control input-error error-margin"
                                             : "form-control field-password"}
                                                name="userName" id={userName.Name} {...formik.getFieldProps(userName.Name)} />
                                        {formik.getFieldMeta(userName.Name).touched &&
                                            formik.getFieldMeta(userName.Name).error != null ? (
                                            <div className="error">
                                                {formik.getFieldMeta(userName.Name).error}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className='field-form-group '>
                                        <label className='form-label' htmlFor="" >Password</label>
                                        <div className='input-box'>
                                            <input

                                                type={(showpassword === false) ? 'password' : 'text'}
                                                className={
                                                    formik.getFieldMeta(userPassword.Name).touched &&
                                                        formik.getFieldMeta(userPassword.Name).error != null
                                                        ? "form-control input-error error-margin"
                                                        : "form-control field-password"
                                                }
                                                name="password" id={userPassword.Name} {...formik.getFieldProps(userPassword.Name)} />
                                            <div id="toggle" >
                                                {
                                                    (showpassword) ? <AiFillEye onClick={toggleShowPassword} /> : <AiFillEyeInvisible onClick={toggleShowPassword} />
                                                }
                                            </div>

                                        </div>

                                    </div>
                                    {formik.getFieldMeta(userPassword.Name).touched &&
                                        formik.getFieldMeta(userPassword.Name).error != null ? (
                                        <div className="error">
                                            {formik.getFieldMeta(userPassword.Name).error}
                                        </div>
                                    ) : null}
                                    <div className='d-flex justify-content-between help-links my-3'>
                                        <div className="form-check">
                                            <input className="form-check-input remember-me" onClick={rememberMe} type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label remember-label" htmlFor="flexCheckDefault">
                                                Remember me
                                            </label>
                                        </div>

                                     
                                    </div>
                                    <button
                                        disabled={
                                            !(formik.dirty && formik.isValid) && !(formik.values.userEmail && formik.values.userPassword)
                                        }
                                        className='btn login-btn' type='submit'>Login</button>
                                </form>


                            </div>
          

                    </div>

            }
            

        </>
    )
}

export default Login
