import React from 'react'
import './Navbar.css'
import logo from '../../Assets/logo.png'
import avater from "../../Assets/Ellipse 1.png"

import { Link, useLocation, useNavigate } from 'react-router-dom'
import {RiArrowDropDownLine} from "react-icons/ri"
import {HiOutlineClipboardList} from "react-icons/hi"
import {CiSaveDown1,CiSaveUp1} from "react-icons/ci"
import {BsBriefcase} from "react-icons/bs"



function Navbar() {

    

    let userName = localStorage.getItem('userName')

    let navigate = useNavigate()
    const {state} = useLocation()
    let location = useLocation()

    function logout(){
        localStorage.removeItem("token")
        localStorage.removeItem("userName")
        
        navigate('/login')
        
    }

 
    return (
        <>
            <div className='top-navbar'>
                <div className='container-fluid'>
                    <div className='row d-flex align-items-center justify-content-between'>
                        <div className=' col-3'>
                            <img src={logo} className="nav-logo" alt="" />
                        </div>
                        <div className=' col-9 d-flex justify-content-end '>
                            <div className='avatar-group'>
                                <div className="dropdown">
                                    <button className="btn border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className='text-white px-2'>Hello,{location.pathname==='/home'?state:userName}</span>
                                       
                                        <RiArrowDropDownLine className='fs-1 text-light' />
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <button className='logout-btn' onClick={()=>{logout()}}>logout</button>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        
        </>
    )
}

export default Navbar
