import React, { useState } from "react";
import { MdAdd } from "react-icons/md";
import { TbChartAreaFilled } from "react-icons/tb"
import { GrUserManager } from "react-icons/gr"
import UserTable from "./tables/UserTable";
import { useNavigate } from "react-router-dom";
import ProjectTable from "./tables/ProjectTable";





function Management() {
  const navigate = useNavigate()
  const [activeUsers, setActiveUsers] = useState(false);

  const [activeProjects, setActiveProjects] = useState(true);




  function ShowElements(value) {
    if (value === "Users") {
      setActiveUsers(true);
      setActiveProjects(false);

    }
    if (value === "Projects") {
      setActiveUsers(false);
      setActiveProjects(true);

    }

  }


  const [editMode, setEditMode] = useState(false)






  return (
    <>

      <div className="container-fluid">
        <div className="management rwd-m">
          <div className="row">
            <div className="col-md-12">

            </div>
          </div>

          <div className="row  ">
            {/* Show buttons */}
            <div className="col-9 tap-btn-group">
              <button
                className={
                  activeProjects
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Projects");
                }}
              >
                Projects
              </button>
              <button
                className={
                  activeUsers
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Users");
                }}
              >
                Users
              </button>




            </div>
            <div className=" col-9 rwd-icon">
              <button className={
                activeUsers
                  ? "stageBtn active text-white border-none"
                  : "stageBtn border-none"
              }
                onClick={() => {
                  ShowElements("Users");
                }}><GrUserManager /></button>

              <button
                className={
                  activeProjects
                    ? "stageBtn active text-white border-none"
                    : "stageBtn border-none"
                }
                onClick={() => {
                  ShowElements("Projects");
                }}
              >
                <TbChartAreaFilled />
              </button>


            </div>

            {/* Add buttons */}
            <div className="col-3 text-end add-btn">
              {activeUsers ? (
                <button
                  className="addBtn"

                  onClick={() => {
                    navigate('/userOperation', {
                      state: {
                        addMode: true
                      }
                    })
                  }}
                >
                  <MdAdd className="me-2" />
                  Add user
                </button>
              ) : activeProjects ? (
                <button
                  className="addBtn"

                  onClick={() => {
                    navigate('/ProjectOperation', {
                      state: {
                        addMode: true
                      }
                    })
                  }}
                >
                  <MdAdd className="me-2" />
                  Add Project
                </button>
              ) : ""}
            </div>
            <div className="col-3 text-end rwd-add-btn">
              {activeUsers ? (
                <button
                  className="addBtn"

                  onClick={() => { setEditMode(false) }}
                >
                  <MdAdd />

                </button>
              ) : activeProjects ? (
                <button
                  className="addBtn"

                  onClick={() => { setEditMode(false) }}
                >
                  <MdAdd />

                </button>
              ) : ""}
            </div>
          </div>

          <div className="row">
            <div className="col-12">

              {activeUsers ? (
                <UserTable editStatus={editMode} />
              ) : activeProjects ? (
                <ProjectTable editStatus={editMode} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Management;
