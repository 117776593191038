
import React,{useState,useEffect} from 'react'
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from "jquery";
    import { createRoot } from "react-dom/client";
    import "datatables.net-buttons/js/dataTables.buttons.js";
    import "datatables.net-buttons/js/buttons.colVis.js";
    import "datatables.net-buttons/js/buttons.flash.js";
    import "datatables.net-buttons/js/buttons.html5.js";


    import { FiEdit } from 'react-icons/fi';
    
    import LoadingScreen from '../../../Component/Navbar/LoadingScreen';
    
    
    
    import { ToastTemplate, ToastType } from '../../../notifications/ToastExecutor';

import ApiCaller from '../../../Api/ApiCaller';
import { useNavigate } from 'react-router-dom';
    import {Tooltip, ButtonToolbar, OverlayTrigger } from 'react-bootstrap';



export default function UserTable() {
    
    
      const navigate = useNavigate()
    
      const [userList,setUserList] = useState([])
    
          // loading state
      const[loading,setLoading]=useState(false)
      const [zoneBody,setZoneBody] = useState({})
    
    
    const [id,setId]=useState('')
      
     async function fillUser(){
      
      try{
        setLoading(true)
        let res = await new ApiCaller(`https://api.cie-contractors.org/user`).getData();
    
        if(res.Status){
         
              setUserList(res.Result);

              setLoading(false)
          
            }else{
              new ToastTemplate(ToastType.Error,res.Message).execute()
    
              setLoading(false)
              
            }
      } catch(err) {
        new ToastTemplate(ToastType.Error,err.Message).execute()
    
       
         setLoading(false)
       
        }
        
        
    
      }
    
     
    
    
    
     
    
    
    

     
    
    
    
       const tooltipEdit =  (
    <Tooltip id="tooltipEdit">
      Edit
    </Tooltip>
  );
        
    
          useEffect(() => {
    
        
            
            $("#table").DataTable({
              stateSave: false,
              bDestroy: true,
       
              language: {
                paginate: {
                  previous: "<",
                  next: ">",
                },
              },
              lengthMenu: [
                [ 5, 10, 15, -1 ],
                [ 5, 10, 15, 'Show all' ]
            ],
              data: userList,
        
              columns: [
              
                {
                  title: `Name`,
                  data: "Username",
                  createdCell: function (td, cellData, rowData, row, col) {
                    $(td).addClass("col-edit");
                  },
                },
               
                {
                    title: `Email`,
                    data: "Email",
                    createdCell: function (td, cellData, rowData, row, col) {
                      $(td).addClass("col-edit");
                    },
                  },
             
                { title: `` },
              ],
              dom:
              "<'row  '<'col-sm-12 col-md-6 d-flex mt-3'f><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'B>>" +
              "t<'row  e_table'<'col-sm-12 col-md-6 d-flex align-items-end justify-content-end 'p><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse   mt-3'l>>",
        
              columnDefs: [
                {
                  target: -1,
                  createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                      <div className="d-flex align-items-center justify-content-around">
                        <ButtonToolbar>
                    <OverlayTrigger placement="top" overlay={tooltipEdit}>
    
                        <button
                          className="deleteBtn "
                          onClick={()=>{navigate("/userOperation",{
                            state:{
                              addMode:false,
                              data:rowData
                            }
                          });}}

                        >
                          <FiEdit/>
                        </button>
                        </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    );
                  },
                },
        
                {
                  defaultContent: "-",
                  targets: ["_all"],
                  className: "dt-body-left",
                },
                 { orderable: false, targets: [2] }
              ],
              buttons: [
                {
                 text:'<i class="fa-solid fa-rotate-right fs-4"></i>',
                 className:"re-btn ",
                 action:function(e,dt,node,config){
                    fillUser()
                 }
                }
             ],
            });
    
    
          
          
          }, [userList]);
    
          useEffect(()=>{
            fillUser()
          },[])
    
      return (
        <>
        {
           loading?<LoadingScreen/>:
           <>
                  <div className='card-table  '>
                                
                                <table id="table" className="display   row-border " style={{ "width": "100%",marginTop:"40px" }}>
                                </table>
                    </div>
                         
                         
                       
           </>
    
        }
    
    
    
      
           
    
            
        </>
      )
    }
    

