import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./Page/Login/Login";

import Navbar from './Component/Navbar/Navbar';
import Management from './Page/Management/Management';

import PageNotFound from './Page/PageNotFound';


import { ToastContainer } from 'react-toastify';
import UserOperation from "./Page/Management/com/UserOperation";
import ProjectOperation from "./Page/Management/com/ProjectOperation";
import Routing from "./routing/Routing";


function App() {
  let navigate = useNavigate()
 



  const location = useLocation()

 

  return (<>
  <ToastContainer/>
    {
      location.pathname === "/" || location.pathname === "/login" ? "" : <Navbar />
    }

   
    <Routing/>

  </>
  );
}

export default App;
