
import { Field, Formik, useFormik, Form, ErrorMessage } from 'formik'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { getInitialValues } from '../../../extensions/FormikExtension'

import { ToastTemplate, ToastType } from '../../../notifications/ToastExecutor';
import LoadingScreen from './../../../Component/Navbar/LoadingScreen';
import { useLocation, useNavigate } from 'react-router-dom'
import ApiCaller from '../../../Api/ApiCaller'

import { CategoryType, CreateProjectSchema, Images, IsActive, LongDescAr, LongDescEn, ShortDescAr, ShortDescEn, titleAr, titleEn } from '../../../validarionSchema/CreateProjectSchema'
import { useRef } from 'react'
import './style/projectOperation.css'
import { AiFillDelete } from 'react-icons/ai'

export default function ProjectOperation() {
    const navigate = useNavigate()

    const [images, setImages] = useState([])
    const fileInputRef = useRef(null)
    const [imageBase64, setImageBase64] = useState('')
    const [mainImageBase64, setMainImageBase64] = useState('')
    const [pathPhotoForImages, setPathPhotoForImages] = useState("")
    // const [arr, setArr] = useState([])
    const [imagesForCreate,setImagesForCreate]=useState([])
    const [pathPhotoForMainImage, setPathPhotoForMainImage] = useState('')
    const [imageUploadMode, setImageUploadMode] = useState("Images")
    const { state } = useLocation()

    const [initialValues, setInitialValues] = useState({
        titleEn: "",
        titleAr: "",
        ShortDescEn: "",
        ShortDescAr: "",
        LongDescEn: "",
        LongDescAr: "",
        Images: "",
        CategoryType: "",
        IsActive: true
    })
    const category = [{
        id: "resident",
        name: "resident "
    },
    {
        id: "industrial",
        name: "industrial"
    },
    {
        id: "admin",
        name: "admin"
    },
    {
        id: "hotel",
        name: "hotel"
    }

    ]
    useEffect(() => {

        if (pathPhotoForImages != 0 && !state?.editMode) {
              imagesForCreate.push(pathPhotoForImages)
            
    
           
        } else if (pathPhotoForImages != 0 && !state?.addMode) {
            imagesArrayFromUpdate.push(pathPhotoForImages)
           
        }

    }, [pathPhotoForImages])

    // useEffect(() => {

       

    // }, [pathPhotoForImages])

    const goBack = () => {
		navigate(-1);
	}

    function handleFormField() {
        const im = []
        const newArray = im.push(state?.data?.Images.split(","))
    
        setInitialValues({
            titleEn: state?.data?.titleEn,
            titleAr: state?.data?.titleAr,
            ShortDescEn: state?.data?.ShortDescEn,
            ShortDescAr: state?.data?.ShortDescAr,
            LongDescEn: state?.data?.LongDescEn,
            LongDescAr: state?.data?.LongDescAr,
            MainImage: state?.data?.MainImage,
            Images:`https://cie-contractors.org/images/`+im.map((img)=>img),
            CategoryType: state?.data?.CategoryType,
            IsActive: state?.data?.IsActive
        })

        if (!state?.addMode) {
           setImages(state?.data?.Images.split(",").map((item)=>`https://cie-contractors.org/images/`+item))  
        }
      


    }
    useEffect(() => {
        if (!state?.addMode) {
            handleFormField()
        }

    }, [])
const [isLoading,setIsLoading]=useState(false)

     function uploadPhoto(filename, data) {


        let uplaodImageBody = {
            filename: filename.toString(),
            data: data.substr(data.indexOf(',') + 1)
        }

        try {
            setIsLoading(true)
            // let response = await new ApiCaller(`https://api.cie-contractors.org/upload/UploadImage`).postData(uplaodImageBody)
            new ApiCaller(`https://api.cie-contractors.org/upload/UploadImage`).postData(uplaodImageBody).then((response)=>{
                
                if (response.Status) {
                   
                    if (imageUploadMode === "Images") {
                  
                      //  setPathPhotoForImages(response.Result)
                        
                         imagesArrayFromUpdate?.push(response?.Result)
                         imagesForCreate?.push(response?.Result)

                     
                        
                    } else if (imageUploadMode === "mianImage") {
                        
                        setPathPhotoForMainImage(response.Result)
                  
        
                    }
                    setTimeout(()=>{ setIsLoading(false)},5000)
                } else {
                    new ToastTemplate(ToastType.Error, response.message).execute()
               
                }  
             
            })
         
            
        } catch (error) {
            new ToastTemplate(ToastType.Error, "Please refer to support").execute()
            setIsLoading(false)
        }
    }


    function imageUploader(e, setFieldValue) {
        let files = e.target.files
        if (files.length === 0) return;
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageBase64(reader.result)

                setFieldValue("Images", URL.createObjectURL(files[i]))
                uploadPhoto(files[i].name, reader.result)

            }
            reader.readAsDataURL(files[i]);

            setImages((prevImages) => [...prevImages,URL.createObjectURL(files[i])])
         
        }

    }



    async function uploadMainPhoto(filename, data) {


        let uplaodImageBody = {
            filename: filename.toString(),
            data: data.substr(data.indexOf(',') + 1)
        }

        try {
            setIsLoading(true)
                       
                     
            let response = await new ApiCaller(`https://api.cie-contractors.org/upload/UploadImage`).postData(uplaodImageBody)

            if (response.Status) {
                setPathPhotoForMainImage(response.Result)

            } else {
                new ToastTemplate(ToastType.Error, response.message).execute()
            }
            setIsLoading(false)
        } catch (error) {
            new ToastTemplate(ToastType.Error, "Please refer to support").execute()
        }
    }

    function mainPhotoHandle(e, setFieldValue) {
        
        const files = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setMainImageBase64(reader.result)

            setFieldValue("MainImage", reader.result)
            uploadMainPhoto(files.name, reader.result)
        }
        reader.readAsDataURL(files);
    }


    const [imagesArrayFromUpdate,setImagesArrayFromUpdate] = useState(state?.data?.Images.split(",")) 

    function deleteImage(index) {
        
        setImages((prevImages) =>
            prevImages.filter((_, i) => i !== index)
        )
        if(!state?.addMode){
            setImagesArrayFromUpdate((copyArray)=>[...copyArray.filter((_, i)=>i!==index)])
        }
        
    }
 

    async function createProject(body) {
       
        try {
            // let response = await new ApiCaller(`https://api.cie-contractors.org/project/create`).postData(body)

            new ApiCaller(`https://api.cie-contractors.org/project/create`).postData(body).then((res)=>{
                
                if (res.Status) {
                    new ToastTemplate(ToastType.Success, res.message).execute()
                    navigate("/Management")
                } else {
                    alert(JSON.stringify(res))
                    new ToastTemplate(ToastType.Error, res.message).execute()
                }
            })
            // if (response.Status) {
            //     new ToastTemplate(ToastType.Success, response.Message).execute()
            //     navigate("/Management")
            // } else {
            //     alert(JSON.stringify(response))
            //     new ToastTemplate(ToastType.Error, response.Message).execute()
            // }
        } catch (error) {
            new ToastTemplate(ToastType.Error, "Please refer to support").execute()
        }
    }
    async function editProject(body) {
        try {
            let response = await new ApiCaller(`https://api.cie-contractors.org/project/update`).postData(body)

            if (response.Status) {
                navigate("/Management")
                new ToastTemplate(ToastType.Success, response.Message).execute()
            } else {
                new ToastTemplate(ToastType.Error, response.Message).execute()
            }
        } catch (error) {
            new ToastTemplate(ToastType.Error, "Please refer to support").execute()
        }
    }


    return (
        <>
        {
            isLoading ? <LoadingScreen /> : null
        }

        <div className='d-flex  justify-content-center vh-100 '>
            <div className='add-project-page'>
                <div className='title text-center'>
                    <h1>project</h1>
                </div>
                <div className='container'>
                    <div className='add-project-form'>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={CreateProjectSchema}
                            onSubmit={(values) => {

                                if (!state?.addMode) {
                                    let imagesGroup = `${state?.data?.Images.split(",")}`
                                    
                                    let editBody = {
                                        titleEn: values.titleEn,
                                        titleAr: values.titleAr,
                                        ShortDescEn: values.ShortDescEn,
                                        ShortDescAr: values.ShortDescAr,
                                        LongDescEn: values.LongDescEn,
                                        LongDescAr: values.LongDescAr,
                                        MainImage: pathPhotoForMainImage,
                                        // Images:imagesGroup,
                                        //  Images:pathPhotoForImages,
                                         Images:imagesArrayFromUpdate.toString(),
                                        // Images:images.map((img)=>img.replace("https://cie-contractors.org/images/","")).toString(),
                                        CategoryType: values.CategoryType,
                                        Id: state?.data?.Id,
                                        IsActive: state?.data?.IsActive
                                    }
                                    editProject(editBody)
                                } else {
                                    let createBody = {
                                        titleEn: values.titleEn,
                                        titleAr: values.titleAr,
                                        ShortDescEn: values.ShortDescEn,
                                        ShortDescAr: values.ShortDescAr,
                                        LongDescEn: values.LongDescEn,
                                        LongDescAr: values.LongDescAr,
                                        MainImage: pathPhotoForMainImage,
                                        // Images: arr.toString(),
                                        Images: imagesForCreate.toString(),
                                        CategoryType: values.CategoryType,
                                        IsActive: values.IsActive === "" ? true : values.IsActive
                                    }
                                    
                                    createProject(createBody)
                                }
                            }}
                        >
                            {({ values, handleChange, touched, errors, dirty, isValid, setFieldValue }) => (
                                <Form>
                                    <div className='row mt-3'>
                                        <div className='col-md-6'>
                                            <label htmlFor="titleEn" className=''>Title</label>
                                            <Field
                                                className={
                                                    touched.titleEn &&
                                                        errors.titleEn != null
                                                        ? " form-control-error"
                                                        : " form-control"
                                                }
                                                name="titleEn"
                                                type="text"
                                            />
                                            <span className='error-text'>
                                                <ErrorMessage name='titleEn' />
                                            </span>
                                        </div>
                                        <div className='col-md-6'>
                                            <label htmlFor="titleAr" className=''>Title Arabic</label>
                                            <Field
                                                className={
                                                    touched.titleAr &&
                                                        errors.titleAr != null
                                                        ? " form-control-error"
                                                        : "form-control"
                                                }
                                                name="titleAr"
                                                type="text"
                                            />
                                            <span>
                                                <ErrorMessage name='titleAr' />
                                            </span>

                                        </div>
                                    </div>
                                    <div className='row  mt-3'>
                                        <div className='col-md-6'>
                                            <label htmlFor="ShortDescEn" className=''>Short Description</label>
                                            <Field
                                                className={
                                                    touched.ShortDescEn &&
                                                        errors.ShortDescEn != null
                                                        ? " form-control-error"
                                                        : "form-control"
                                                }

                                                name="ShortDescEn"
                                                type="text"
                                            />
                                            <span className='error-text'>
                                                <ErrorMessage name='ShortDescEn' />
                                            </span>
                                        </div>
                                        <div className='col-md-6'>
                                            <label htmlFor="ShortDescAr" className=''>Short Description Arabic</label>
                                            <Field
                                                className={
                                                    touched.ShortDescAr &&
                                                        errors.ShortDescAr != null
                                                        ? " form-control-error"
                                                        : "form-control"
                                                }

                                                name="ShortDescAr"
                                                type="text"
                                            />
                                            <span>
                                                <ErrorMessage name='ShortDescAr' />
                                            </span>

                                        </div>
                                    </div>

                                    <div className='row  mt-3'>
                                        <div className='col-md-6'>
                                            <label htmlFor="LongDescEn" className=''>Long Description English</label>
                                            <Field
                                                className={
                                                    touched.LongDescEn &&
                                                        errors.LongDescEn != null
                                                        ? " form-control-error"
                                                        : "form-control"
                                                }

                                                name="LongDescEn"
                                                as='textarea'
                                                type="text"
                                            />
                                            <span className='error-text'>
                                                <ErrorMessage name='LongDescEn' />
                                            </span>
                                        </div>
                                        <div className='col-md-6'>
                                            <label htmlFor="LongDescAr" className=''>Long Description Arabic</label>
                                            <Field
                                                className={
                                                    touched.LongDescAr &&
                                                        errors.LongDescAr != null
                                                        ? " form-control-error"
                                                        : "form-control"
                                                }

                                                name="LongDescAr"
                                                as='textarea'
                                                type="text"
                                            />
                                            <span>
                                                <ErrorMessage name='LongDescAr' />
                                            </span>

                                        </div>
                                    </div>
                                    <div className='row  mt-3'>
                                        <div className='col-12'>
                                            <label htmlFor="Category Type" className=''>Category Type</label>
                                            <Field
                                                as="select"
                                                className={
                                                    touched.CategoryType &&
                                                        errors.CategoryType != null
                                                        ? " form-select-error"
                                                        : "form-select"
                                                }

                                                name="CategoryType"
                                                id="CategoryType"

                                            >
                                                <option value="">Select Type</option>
                                                {category.map((type, i) => {
                                                    return (
                                                        <option key={type.id} value={i}>
                                                            {type.name}
                                                        </option>
                                                    );
                                                })}
                                            </Field>
                                            <span className='error-text'>
                                                <ErrorMessage name='CategoryType' />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='row  mt-5'>
                                        <div className='col-12'>
                                            <div className='image-uploader-container'>
                                                <div className='text-layout'>
                                                    <h4>Upload Image</h4>
                                                </div>
                                                <div className='input-layout'>
                                                    <input type="file" className="image-input" multiple ref={fileInputRef}  onChange={(e) => { imageUploader(e, setFieldValue); setImageUploadMode("Images");}} />
                                                </div>
                                                <div className='img-layout'>
                                                    <div className='row g-1'>
                                                        {
                                                                 images.map((images, i) => (
                                                                    <div key={i} className='col-4'>
                                                                        <div className='img-container'>
                                                                            <img className='img' src={images} alt="" />
                                                                            
                                                                            <div className='btn-layout'>
                                                                                <button type='button' className='delete-btn-img' onClick={() => { deleteImage(i)}}><AiFillDelete className='text-danger' /></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                        }


                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <div className='main-img-uploader'>
                                                <div className='m-text-layout'>
                                                    <h4>Upload Image</h4>
                                                </div>
                                                <div className='m-input-layout'>
                                                    <input type="file" className='m-image-input' name='MainImage' accept="image/*" onChange={(e) => { setImageUploadMode("mianImage"); mainPhotoHandle(e, setFieldValue) }} />
                                                </div>
                                                <div className='m-img-layout'>
                                                    {
                                                        state?.editMode ? <img src={imageUploadMode !== "mianImage" ? `https://cie-contractors.org/images/` + values.MainImage : values.MainImage} className='img' /> :
                                                            <img src={mainImageBase64} className='img' />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <div className='form-footer my-5'>
                                            <button onClick={goBack} className={"add-modal-btn"} style={{backgroundColor:'#a61211'}}  >
                                                    {
                                                        "Back To Home "
                                                    }
                                                </button>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button type={'submit'} className={"add-modal-btn"}   >
                                                    {
                                                        state?.addMode ? "Create Project" : "Apply Changes"
                                                    }
                                                </button>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </div>
                </div>
            </div>
        </div>
        </>

    )
}
