import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var userName = new Field({ Name: "userName", InitialValue: "" })
export var Email = new Field({ Name: "Email", InitialValue: "" })
export var password = new Field({ Name: "password", InitialValue: "" })


export const CreateUserSchema = Yup.object({
	userName: Yup.string().required('Please Enter User Name'),
    Email: Yup.string().required('Please Enter Your Email'),
    password: Yup.string().required('Please Enter Your password'),
    
})