
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { getInitialValues } from '../../../extensions/FormikExtension'


import { ToastTemplate, ToastType } from '../../../notifications/ToastExecutor';
import LoadingScreen from './../../../Component/Navbar/LoadingScreen';
import { useLocation, useNavigate } from 'react-router-dom'
import ApiCaller from '../../../Api/ApiCaller'
import { CreateUserSchema, Email, password, userName } from '../../../validarionSchema/CreateUserSchema'


export default function UserOperation() {
    const [loading, setLoading] = useState(false)
    const navigate=useNavigate()
    const { state } = useLocation()
    const goBack = () => {
		navigate(-1);
	}
    async function addUser() {

        let body =
        {
            username: formik.getFieldProps(userName.Name).value,
            email: formik.getFieldProps(Email.Name).value,
            password: formik.getFieldProps(password.Name).value,
        }
        setLoading(true)



        try {
            let res = await new ApiCaller(`https://api.cie-contractors.org/user/create`).postData(body);

            if (res.Status) {
                new ToastTemplate(ToastType.Success, res.message).execute()

                navigate('/Management')
                setLoading(false)
                
            } else {
                new ToastTemplate(ToastType.Error, res.message).execute()



                setLoading(false)
            }
        }
        catch (err) {
            new ToastTemplate(ToastType.Error, err.message).execute()


            setLoading(false)

        }







    }

    async function handleUserForm(data) {
           
        formik.setFieldValue(userName.Name, data?.Username || "")
        formik.setFieldValue(Email.Name, data?.Email || "")
    

    }

    useEffect(() => {
        if (!state?.addMode) {
            handleUserForm(state?.data)
        }

    }, [state])

    async function editUser() {

        try {

            let body =
            {
                username: formik.getFieldProps(userName.Name).value,
                email: formik.getFieldProps(Email.Name).value,
                password: formik.getFieldProps(password.Name).value,
                UserId:state?.data?.Id

            }



            setLoading(true)
            let res = await new ApiCaller(`https://api.cie-contractors.org/user/update`).postData(body);

            if (res.Status) {
                new ToastTemplate(ToastType.Success, res.Message).execute()

                navigate('/Management')
                setLoading(false)

            } else {
                new ToastTemplate(ToastType.Error, res.Message).execute()

                setLoading(false)

            }
        } catch (err) {
            new ToastTemplate(ToastType.Error, err.Message).execute()


            setLoading(false)

        }








    }

    const formik = useFormik({
        initialValues: getInitialValues([userName, Email, password]),
        validationSchema: CreateUserSchema,
        onSubmit: async () => {
            if (state?.addMode) {
                addUser()
            } else {
                editUser()
            }



            formik.resetForm()

        }
    });



 


    return (
        <>

            {
                loading ? <LoadingScreen /> :

                    <>

                        <div className="modal-header  border-0">
                            <h1 className="modal-title fs-5 title-add-madal" id="exampleModalLabel" >
                                {
                                    state?.addMode ? "Add User" : "Edit User"
                                }

                            </h1>

                        </div>
                        <div className="modal-body">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.handleSubmit();
                                }}
                                className='d-flex align-items-center flex-column'
                            >
                                <div className="field-group">
                                    <label htmlFor="userName" className="d-block ModelNumber-label">
                                        User Name
                                    </label>
                                    <input
                                        className={
                                            formik.getFieldMeta(userName.Name).touched &&
                                                formik.getFieldMeta(userName.Name).error != null
                                                ? "form-control input-error error-margin Model-Number"
                                                : "form-control Model-Number"
                                        }
                                        type="text"
                                        id={userName.Name}
                                        name="userName"
                                        {...formik.getFieldProps(userName.Name)}
                                    />
                                    {formik.getFieldMeta(userName.Name).touched &&
                                        formik.getFieldMeta(userName.Name).error != null ? (
                                        <div className="error">
                                            {formik.getFieldMeta(userName.Name).error}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-group">
                                    <label htmlFor="Email" className="d-block ModelNumber-label">
                                        Email
                                    </label>
                                    <input
                                        className={
                                            formik.getFieldMeta(Email.Name).touched &&
                                                formik.getFieldMeta(Email.Name).error != null
                                                ? "form-control input-error error-margin Model-Number"
                                                : "form-control Model-Number"
                                        }
                                        type="email"
                                        id={Email.Name}
                                        name="Email"
                                        {...formik.getFieldProps(Email.Name)}
                                    />
                                    {formik.getFieldMeta(Email.Name).touched &&
                                        formik.getFieldMeta(Email.Name).error != null ? (
                                        <div className="error">
                                            {formik.getFieldMeta(Email.Name).error}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="field-group">
                                    <label htmlFor="password" className="d-block ModelNumber-label">
                                        password
                                    </label>
                                    <input
                                        className={
                                            formik.getFieldMeta(password.Name).touched &&
                                                formik.getFieldMeta(password.Name).error != null
                                                ? "form-control input-error error-margin Model-Number"
                                                : "form-control Model-Number"
                                        }
                                        type="password"
                                        id={password.Name}
                                        name="password"
                                        {...formik.getFieldProps(password.Name)}
                                    />
                                    {formik.getFieldMeta(password.Name).touched &&
                                        formik.getFieldMeta(password.Name).error != null ? (
                                        <div className="error">
                                            {formik.getFieldMeta(password.Name).error}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="modal-footer border-0 d-flex justify-content-center">
                                <button onClick={goBack} className={"add-modal-btn"} style={{backgroundColor:'#a61211'}}  >
                                                    {
                                                        "Back To Home "
                                                    }
                                                </button>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <button

                                        type="submit"
                                        className={!(formik.dirty && formik.isValid) ? "disabled-btn" : "add-modal-btn"}
                                       
                                        disabled={!(formik.dirty && formik.isValid)}
                                    >
                                        {
                                            state?.addMode ? "Add " : "Apply changes"
                                        }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>

            }
        </>

    )
}
