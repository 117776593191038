import React from 'react'

export default function LoadingScreen() {
  return (
    <div className='loadingScreen'>
     <h2 className="animate">Loading</h2>
    </div>
  
 
   
  )
}
