import axios from "axios";


const ApiUtils={
     setAxiosDefaultHeaders(axios) {
        axios.defaults.headers.common["Authorization"] =localStorage.getItem('token');
    }

}

export default ApiUtils;