import { Field } from '../extensions/FormikExtension'
import * as Yup from "yup";
export var userName = new Field({ Name: "userName", InitialValue: "" })
export var userPassword = new Field({ Name: "userPassword", InitialValue: "" })

export const loginSchema = Yup.object({
	userName: Yup.string().required('Please Enter your User Name'),

    userPassword: Yup.string()
    .required('Please Enter  Password')
    
})