
import * as Yup from "yup";


export const CreateProjectSchema = Yup.object({
	titleEn: Yup.string().required('Please Enter Project Title'),
	titleAr: Yup.string(),
    ShortDescEn: Yup.string(),
    ShortDescAr: Yup.string(),
    LongDescEn: Yup.string(),
    LongDescAr: Yup.string(),
    MainImage: Yup.string().required('Please Upload Photo'),
    Images: Yup.string().required('Please Upload Photo'),
    CategoryType: Yup.string().required('Please Enter Photo Type of Category'),
   
})