
import React, { useState, useEffect } from 'react'
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { createRoot } from "react-dom/client";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import deleteIcon from "../../../Assets/icon/Delete.svg";

import { FiEdit } from 'react-icons/fi';

import LoadingScreen from '../../../Component/Navbar/LoadingScreen';



import { ToastTemplate, ToastType } from '../../../notifications/ToastExecutor';

import ApiCaller from '../../../Api/ApiCaller';
import { useNavigate } from 'react-router-dom';

import {Tooltip, ButtonToolbar, OverlayTrigger } from 'react-bootstrap';

export default function ProjectTable() {


  const navigate = useNavigate()

  const [projectList, setProjectList] = useState([])

  // loading state
  const [loading, setLoading] = useState(false)
  const [zoneBody, setZoneBody] = useState({})


  const [id, setId] = useState('')

  async function fillProject() {

    try {
      setLoading(true)
      let res = await new ApiCaller(`https://api.cie-contractors.org/project`).getData();

      if (res.Status) {
       
        setProjectList(res.Result);

        setLoading(false)

      } else {
        new ToastTemplate(ToastType.Error, res.Message).execute()

        setLoading(false)

      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.Message).execute()


      setLoading(false)

    }



  }


  







  async function deleteProjects(id) {
    let getid = { Id: id }



    try {
      setLoading(true)
      let res = await new ApiCaller(`https://api.cie-contractors.org/project/SwitchStatus`).postData(getid);

      if (res.Status) {
       
        new ToastTemplate(ToastType.Success, res.Message).execute()
        fillProject()

        setLoading(false)

      } else {
        new ToastTemplate(ToastType.Error, res.Message).execute()



        setLoading(false)
      }
    } catch (err) {
      new ToastTemplate(ToastType.Error, err.Message).execute()



      setLoading(false)

    }







  }


  async function getProjectByIdapi(id) {
    try {
      let response = await new ApiCaller(`https://api.cie-contractors.org/project/${id}`).getData()

      if (response.Status) {
    
        navigate("/ProjectOperation", {
          state: {
            data: response.Result,
            editMode: true
          }
        })
        new ToastTemplate(ToastType.Success, response.Message).execute()
      } else {
        new ToastTemplate(ToastType.Error, response.message).execute()
      }
    } catch (error) {
      new ToastTemplate(ToastType.Error, "Please refer to support").execute()
    }
  }


  const tooltipDelete =  (
    <Tooltip id="tooltipDelete">
      Delete
    </Tooltip>
  );
  const tooltipUndo =  (
    <Tooltip id="tooltipUndo">
      undo 
    </Tooltip>
  );
  const tooltipEdit =  (
    <Tooltip id="tooltipEdit">
      Edit 
    </Tooltip>
  );

  useEffect(() => {



    $("#table").DataTable({
      stateSave: false,
      bDestroy: true,

      language: {
        paginate: {
          previous: "<",
          next: ">",
        },
        
      },
      lengthMenu: [
        [5, 10, 15, -1],
        [5, 10, 15, 'Show all']
      ],
      iDisplayLength: -1,
      data: projectList,

      columns: [

        {
          title: `Title English`,
          data: "titleEn",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },

        {
          title: `Title Arabic`,
          data: "titleAr",
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).addClass("col-edit");
          },
        },


        {
          title: `Image`,

        },

        { title: `` },
      ],
      dom:
        "<'row  '<'col-sm-12 col-md-6 d-flex mt-3'f><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse mb-5'B>>" +
        "t<'row  e_table'<'col-sm-12 col-md-6 d-flex align-items-end justify-content-end 'p><'col-sm-6  flex-row-reverse col-md-6 d-flex flex-row-reverse   mt-3'l>>",

      columnDefs: [
        {
          target: 3,
          createdCell: (td, cellData, rowData, row, col) => {
            createRoot(td).render(
              <div className="d-flex align-items-center justify-content-around">
                {
                  rowData.IsActive === 1 ?
                  <ButtonToolbar>
                    <OverlayTrigger placement="top" overlay={tooltipDelete}>
                    <button
                      onClick={() => { deleteProjects(rowData.Id) }}
                      className="deleteBtn border-0 bg-transparent my-3"

                    >
                      <img src={deleteIcon} alt="" />
                    </button>
                    </OverlayTrigger>
                    </ButtonToolbar>
                    :
                    <ButtonToolbar>
                    <OverlayTrigger placement="top" overlay={tooltipUndo}>
                    <button
                    
                      onClick={() => { deleteProjects(rowData.Id) }}
                      className="deleteBtn border-0 bg-transparent my-3"

                    >
                    <i class="fa-solid fa-arrow-rotate-left"></i>
                    </button>
                    </OverlayTrigger>
                    </ButtonToolbar>
                    }
                    <ButtonToolbar>
                    <OverlayTrigger placement="top" overlay={tooltipEdit}>
                <button
                  className="deleteBtn  my-3"
                  onClick={() => {
                  
                    getProjectByIdapi(rowData.Id)
                    
                  }}

                >
                  <FiEdit />
                </button>
                </OverlayTrigger>
                    </ButtonToolbar>




              </div>
            );
          },
        },
        {
          target: -2,
          createdCell: (td, cellData, rowData, row, col) => {
            createRoot(td).render(
              <div className="d-flex align-items-center justify-content-around">
                <img src={`https://cie-contractors.org/images/` + rowData.MainImage} className='project-photo' alt="" />

              </div>
            );
          },
        },

        {
          defaultContent: "-",
          targets: ["_all"],
          className: "dt-body-left",
        },
        { orderable: false, targets: [1] }
      ],
      buttons: [
        {
          text: '<i class="fa-solid fa-rotate-right fs-4"></i>',
          className: "re-btn ",
          action: function (e, dt, node, config) {
            fillProject()
          }
        }
      ],
    });




  }, [projectList]);

  useEffect(() => {
    fillProject()
  }, [])

  return (
    <>
      {
        loading ? <LoadingScreen /> :
          <>
            <div className='card-table  '>

              <table id="table" className="display   row-border " style={{ "width": "100%", marginTop: "40px" }}>
              </table>
            </div>



          </>

      }


     



    </>
  )
}


